<template>
  <v-btn :disabled="disabled" :loading="loading" @click="$emit('click')" tile outlined><slot/></v-btn>
</template>
<script>

export default ({
  props: {
    disabled: false,
    loading:false,
  }
});

</script>